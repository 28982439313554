

function PlayerToggle({ set, current}) {
    const playerSelectChangeHandler = (event) => set(parseInt(event.target.value));

  return (
    <div style={{ ...styles.playerSelect }}>
        <h3 style={{ ...styles.playerSelectTitle }}>Number of player(s):</h3>
          <select style={{ ...styles.playerSelectSelect }} onChange={(e) => playerSelectChangeHandler(e)} defaultValue={current}>
            <option value="1">1 Player</option>
            <option value="2">2 Players</option>
        </select>
    </div>
  );
}

const styles = {
  playerSelect: {
    color: "white",
    marginTop: "34px",
  },
  playerSelectTitle: {
    color: "white",
    display: "inline",
    marginRight: "11px"
  },
  playerSelectSelect: {
    color: "white",
    display: "inline",
    fontSize: "20px",
    fontWeight: "600",
    border: "none"

  },
}

export default PlayerToggle;