import { Link } from "react-router-dom";


function Guide() {
    return (
        <div style={{ ...styles.guide }}>
            <h1 style={{ ...styles.guideTitle }}>Button Map:</h1>
            <div style={{ ...styles.guideText }}>
                <p><span style={{ ...styles.buttonText }}>A</span> : Player 1 Move Left</p>
                <p><span style={{ ...styles.buttonText }}>D</span> : Player 1 Move Right</p>
                <p><span style={{ ...styles.buttonText }}>Spacebar</span> : Player 1 Jump</p>
                <p><span style={{ ...styles.buttonText }}>E</span> : Player 1 Melee</p> <br/>
                <p><span style={{ ...styles.buttonText }}>Numpad 4</span> : Player 2 Move Left</p>
                <p><span style={{ ...styles.buttonText }}>Numpad 6</span> : Player 2 Move Right</p>
                <p><span style={{ ...styles.buttonText }}>Numpad 0</span> : Player 2 Jump</p>
                <p><span style={{ ...styles.buttonText }}>Numpad +</span> : Player 2 Melee</p><br/>
                <p><span style={{ ...styles.buttonText }}>Escape</span> : Pause</p>
                <p><span style={{ ...styles.buttonText }}>F5</span> : Restart from level 1</p>
                {/* <p>(Debug) <span style={{ ...styles.buttonText }}>H</span> : View hitboxes</p> */}
            </div>
            <Link to="/" style={{ ...styles.guideReturn }}>Return Home</Link>
        </div>
    );
}

const styles = {
  guide: {
    color: "white",
    marginTop: "12vh",
    textAlign: "center",
  },
  guideTitle: {
    color: "forestgreen",
    marginBottom: "44px",
    fontSize: "50px",
  },
  guideReturn: {
    color: "white",
    fontSize: "28px",
  },
  guideText: {
    color: "white",
    fontSize: "20px",
    fontWeight: "600",
    textAlign: "center",
    marginBottom: "44px",
  },
  buttonText: {
    color: "forestgreen",
  },
};

export default Guide;