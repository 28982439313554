import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import PlayGame from "./Pages/PlayGame/PlayGame";
import PlayGameTwo from "./Pages/PlayGame/PlayGameTwo";
import Scoreboards from "./Pages/Scoreboards/Scoreboards";
import NotFound from "./Pages/NotFound/NotFound";
import Guide from "./Pages/Guide/Guide";


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/Scoreboards" exact element={<Scoreboards />} />
          <Route path="/Guide" exact element={<Guide />} />
          <Route path="/Play" exact element={<PlayGame />} />
            <Route path="/PlayTwo" exact element={<PlayGameTwo />} />
          <Route path="/" exact element={<Home />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
