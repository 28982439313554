import { useState } from 'react';
import { Link } from "react-router-dom";
import DifficultyToggle from "../../Components/DifficultyToggle/DifficultyToggle";
import PlayerToggle from '../../Components/PlayerToggle/PlayerToggle';
import NameSelector from "../../Components/NameSelector/NameSelector";

function Home() {
  const [nbPlayer, setNbPlayer] = useState(1);

  return (
    <div style={{ ...styles.container }} className="menu_container">
      <h1 className="title">Multiplayer Adventure</h1>

      <Link to={nbPlayer !== 2 ? "/Play" : "PlayTwo"} style={{ ...styles.btnPlay }}>
      Play
      </Link>

      <NameSelector />
      <PlayerToggle current={nbPlayer} set={setNbPlayer} />
      <DifficultyToggle />

      {/* <div style={{ ...styles.btnScoreboard }}>
        <Link to="/Scoreboards">View Scoreboards</Link>
      </div> */}

      <div style={{ ...styles.btnGuide }}>
        <Link to="/Guide"> Button Map </Link>
      </div>
    </div>
  );
}

const styles = {
  container: {
    "textAlign": "center"
  },
  // title: {
  //   "textDecoration": "underline",
  //   "color": "orange",
  //   "marginTop": "5%",
  //   "marginBottom": "30px",
  //   "verticalAlign": "center",
  // },
  btnPlay: {
    "fontSize": "32px",
    "color": "#77779f",
    "fontWeight": "bold",
    "textDecoration": "none",
  },
  btnScoreboard: {
    "fontSize": "32px",
    "color": "#77779f",
    "fontWeight": "bold",
    "textDecoration": "none",
    "marginTop": "30px",
  },
  btnGuide: {
    "marginTop": "2%",
    "fontSize": "28px",
    "color": "#FFF",
    "textDecoration": "none",
  },
}

export default Home;