import { useState } from "react";

function NameSelector() {
  const previousnameSelector = localStorage.getItem("username")?.replace(/[^a-z0-9]/gi, '') || "Adventurer";
  const [username, setUsername] = useState(previousnameSelector);
  const nameSelectorChangeHandler = (event) => {
    const newUsername = event.target.value?.replace(/[^a-z0-9]/gi, '') || '';
    localStorage.setItem("username", newUsername);
    setUsername(newUsername);
  };

  return (
    <div style={{ ...styles.nameSelector }}>
      <h3 style={{ ...styles.nameSelectorTitle }}>Party Display Name:</h3>
      <input style={{ ...styles.nameSelectorSelect }} placeholder="Adventurer" onChange={nameSelectorChangeHandler} value={username} maxLength={25}/>
    </div>
  );
}

const styles = {
  nameSelector: {
    color: "white",
    marginTop: "34px",
  },
  nameSelectorTitle: {
    color: "white",
    display: "inline",
    marginRight: "11px"
  },
  nameSelectorSelect: {
    color: "white",
    display: "inline",
    fontSize: "20px",
    fontWeight: "600",
    border: "none",
    borderBottom: "2px solid white",
    borderRadius: "8px",
    textAlign: "center"
  },
}

export default NameSelector;