// import axios from 'axios';
// import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from "react-router-dom";

function Scoreboards() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const level = data[currentPage-1];

  // useEffect(() => {
  //   async function fetchData() {
  //     const result = await axios.get(
  //       `http://4.205.1.19/server/api/v1/games/get-levels`
  //     );
  //     console.log(result);
  //     setData(result.data.sort() || []);
  //   }
  //   fetchData();
  // }, []);

  return (
    <div style={{...styles.container}}>
      <h1 style={{...styles.title}}>Scoreboards</h1>

      <Link to="/" style={{ ...styles.btnReturn }}>Return Home</Link>
      { level &&
        <div key={level.name} style={{ ...styles.lvlContainer }}>
          <button style={{ ...styles.pageBtn }} onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}>{currentPage > 1 ? "Previous" : "-------"}</button>
          {level.name}
          <button style={{ ...styles.pageBtn }} onClick={() => setCurrentPage(Math.min(currentPage + 1, data.length))}>{currentPage < data.length ? "Next" : "----"}</button>

          <div style={{ ...styles.gameContainer }}>
            Average Completion Time: {Math.round((level.totalTimeInSeconds / level.totalRuns) * 10000) / 10000}s, Total Completed Runs: {level.totalRuns}, Completion Rate: {level.totalStartedRuns === 0 ? 0 :Math.round((level.totalRuns/level.totalStartedRuns) * 10000) / 10000 * 100}%
            <table style={{ margin: "auto" }}>
              <thead>
                <tr>
                  <th style={{ ...styles.headerItem }}>Name</th>
                  <th style={{ ...styles.headerItem }}>Difficulty</th>
                  <th style={{ ...styles.headerItem }}>Players</th>
                  <th style={{ ...styles.headerItem }}>Difference in Lives</th>
                  <th style={{ ...styles.headerItem }}>Time</th>
                </tr>
              </thead>
              <tbody>
              {
                level.games.sort((a, b) => { return a.completionTime - b.completionTime })
                  .map((game, i) =>
                    <tr key={i}>
                      <td>{game.displayName.replace(/[^a-zA-Z0-9]+/g, "-")}</td>
                      <td>{game.difficulty}</td>
                      <td>{game.nbPlayers}</td>
                      <td>{game.lifeDifference}</td>
                      <td>{new Date(game.completionTime).toISOString().slice(11, -1)}</td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      }
    </div>
  );
}

const styles = {
  headerItem: {
    "minWidth": "250px",
  },
  lvlContainer: {
    "color": "white",
    "marginTop": "40px",
    "fontSize": "50px"
  },
  gameContainer: {
    "marginTop": "5px",
    "fontSize": "20px"
  },
  container: {
    "textAlign": "center"
  },
  title: {
    "textDecoration": "underline",
    "color": "orange",
    "marginTop": "5%",
    "marginBottom": "30px",
    "verticalAlign": "center",
  },
  btnReturn: {
    "fontSize": "32px",
    "color": "#77779f",
    "fontWeight": "bold",
    "textDecoration": "none",
  },
  pageBtn: {
    "margin": "30px",
    "border": "none",
    "color": "white",
  }
}

export default Scoreboards;