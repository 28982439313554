

function DifficultyToggle() {
    const difficultyChangeHandler = (event) => localStorage.setItem("difficulty", event.target.value);
    const previousDifficulty = parseInt(localStorage.getItem("difficulty")) || 0;

  return (
    <div style={{ ...styles.difficulty }}>
        <h3 style={{ ...styles.difficultyTitle }}>Difficulty:</h3>
          <select style={{ ...styles.difficultySelect }} onChange={(e) => difficultyChangeHandler(e)} defaultValue={previousDifficulty}>
            <option value="0">Easy</option>
            <option value="1">Normal</option>
            <option value="2">Hard</option>
        </select>
    </div>
  );
}

const styles = {
  difficulty: {
    color: "white",
    marginTop: "34px",
  },
  difficultyTitle: {
    color: "white",
    display: "inline",
    marginRight: "11px"
  },
  difficultySelect: {
    color: "white",
    display: "inline",
    fontSize: "20px",
    fontWeight: "600",
    border: "none"

  },
}

export default DifficultyToggle;