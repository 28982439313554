import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div style={{ ...styles.container }}>
      <h1>404</h1>
      <h2 style={{...styles.title}}>Oops! This path to a new adventure does not exist!</h2>

      <Link to="/" style={{ ...styles.btnPlay }}>Return Home</Link>
    </div>
  );
}

const styles = {
  container: {
    "textAlign": "center",
    "margin-top": "25vh"
  },
  title: {
    // "color": "orange",
    "marginBottom": "10vh",
    "verticalAlign": "center",
  },
  btnPlay: {
    "fontSize": "28px",
    "color": "#77779f",
    "fontWeight": "bold",
    "textDecoration": "none",
  },
}

export default NotFound;